<template>
  <div class="container-page" style="padding-left: 40px; padding-right: 40px">
    <div class="about-section">
      <h2>Privacy policy</h2>
      <div class="paragraph body-1">
        Some templates require you to upload data, here is how it is used in
        plain English.
      </div>
    </div>
    <div class="about-section">
      <h2>What data is collected?</h2>
      <div class="paragraph body-1">
        The file, typically an image, that is uploaded by you gets stored on the
        server until your template is generated.
      </div>
    </div>
    <div class="about-section">
      <h2>Why is this data collected?</h2>
      <div class="paragraph body-1">
        The tools behind Cutting Templates offer great flexibility, but some
        templates (including this one) need access to an input file to function.
        This input file needs to be stored somewhere.
      </div>
    </div>
    <div class="about-section">
      <h2>How long do you store this data?</h2>
      <div class="paragraph body-1">
        The file is stored until the template is done generating, not a
        millisecond longer.
      </div>
    </div>
    <div class="about-section">
      <h2>Who gets access to this data?</h2>
      <div class="paragraph body-1">
        The tools required to generate the template requested by you. It is NOT
        accessible to 3rd parties, meaning there is no publically accessible url
        / link for your file. Cutting Templates is not an image hoster.
      </div>
    </div>
    <div class="about-section">
      <h2>What else do you do with my data?</h2>
      <div class="paragraph body-1">
        Nothing. You upload and request a template to be built, then we generate
        your template, then we delete the file immediately.
      </div>
    </div>
    <div class="about-section">
      <h2>Do you share my data with 3rd parties?</h2>
      <div class="paragraph body-1">
        No, absolutely not. Such practice is disgusting.
      </div>
    </div>
    <div class="about-section">
      <h2>Additional questions?</h2>
      <div class="paragraph body-1">
        Reach out to cuttingtemplates@gmail.com or use the social media
        channels. Links to those can be found on the support page.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutPage",
};
</script>

<style scoped>
.about-section {
  padding: 20px;
  background: var(--main-bg-color);
  border-bottom: 2px solid var(--secondary-bg-color);
  border-radius: 15px;
  box-shadow: var(--first-shadow), var(--second-shadow);
}

.container-page {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

h2 {
  margin-top: 0px;
}
</style>
